import { mapState } from 'vuex';
import CommonConst from '@/constants/CommonConst';
import TransferService from '@/services/API/transfer.service';
import userService from '@/services/API/user.service';

const EXPIRATION = 31536000;

export default {
	name: 'SideNavBar',
	props: ['userName', 'userImage'],
	data() {
		return {
			defaultAvatar: ['ClientAvatar.png', 'CreatorAvatar.png'],
		}
	},
	computed: {
		...mapState(['creatorInfo', 'projectId']),
		projectMenuUrl() {
			if (!this.creatorInfo?.id) return;

			let url;

			if (this.creatorInfo.authority === CommonConst.AUTHORITY.CREATOR) {
				url = `/adminApp/creatormenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/projectmenu`;
			} else if (this.creatorInfo.authority === CommonConst.AUTHORITY.ADMIN) {
				url = `/adminApp/adminmenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/projectmenu`;
			}

			let routeData = this.$router.resolve({
				path: url,
			});

			return routeData.href;
		},
		avatar() {
			if (this.defaultAvatar.includes(this.userImage)) {
				return require(`../../assets/RightSideBarIcon/${this.userImage}`)
			}
			else {
				return this.userImage
			}
		},
		editProjectUrl() {
			if (!this.creatorInfo?.id) return;

			let url;
			if (this.creatorInfo.authority === CommonConst.AUTHORITY.CREATOR) {
				url = `/adminApp/creatormenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/editproject`;
			} else if (this.creatorInfo.authority === CommonConst.AUTHORITY.ADMIN) {
				url = `/adminApp/adminmenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/editproject`;
			}

			let routeData = this.$router.resolve({
				path: url,
				query: {
					projectid: this.projectId,
				},
			});

			return routeData.href;
		},
		myPageUrl() {
			if (!this.creatorInfo?.id) return;

			let url;
			if (this.creatorInfo.authority === CommonConst.AUTHORITY.CREATOR) {
				url = `/adminApp/creatormenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/mypage`;
			} else if (this.creatorInfo.authority === CommonConst.AUTHORITY.ADMIN) {
				url = `/adminApp/adminmenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/mypage`;
			}

			let routeData = this.$router.resolve({
				path: url,
			});

			return routeData.href;
		},
	},
	methods: {
		onAvatarClick() {
			this.$refs.fileInput.click();
		},
		onUploadFile(event) {
			let file = event.target.files[0];
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async () => {
				let response =
					await TransferService.postProfilePicture({
						content: reader.result.toString(),
						file_name: file.name,
						user_id: this.creatorInfo.id,
						sub_folder: 'avatar',
					});
				if (response.status == 200) {
					let key = response.data.key;
					this.updateUserProfilePicture(key);
				} else {
					console.log("error uploading IMG");
				}
			};
		},
		async updateUserProfilePicture(key) {
			let response = await userService.update(
				this.creatorInfo.id,
				{
					picture_key: key,
				}
			);
			if (response.status == 200) {
				let newImage = await this.getFileUrl(key, EXPIRATION);
				this.$emit('on-update-avatar', newImage);
			}
		},
		async getFileUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);

				console.log('%c Get image url successfully!', 'color: red');
				return response.data.link;
			} catch (error) {
				console.log(error);
				return "";
			}
		},
	}
};
