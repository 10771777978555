import SideBarLeft from '@/components/SideBarLeft/SideBarLeft.vue';
import SideBarRight from '@/components/SideBarRight/SideBarRight.vue';
import SideNavBar from '@/components/SideNavBar/SideNavBar.vue';
import DrawerLeft from '@/components/DrawerLeft/DrawerLeft.vue';
import DrawerRight from '@/components/DrawerRight/DrawerRight.vue';
import LiveScreen from '@/views/General/LiveScreen/LiveScreen.vue';
import ComodeAI from '@/views/General/ComodeAI/ComodeAI.vue';
import CoCreateScreen from '@/views/General/CoCreateScreen/CoCreateScreen.vue';
import LiveDesign from '@/views/General/LiveDesignScreen/LiveDesignScreen.vue';
import AddOnFolder from '@/views/General/AddOnFolderScreen/AddOnFolderScreen.vue';
import ChatScreen from '@/views/General/ChatScreen/ChatScreen.vue';
import ShareScreen from '@/views/General/ShareScreen/ShareScreen.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import MemoScreenMinimum from '../General/MemoScreen/MemoScreenMinimum.vue';
import MemoScreenFull from '../General/MemoScreen/MemoScreenFull.vue';
import CountDown from '@/components/CountDown/CountDown.vue';
import Settlement from '@/views/CreatorApp/SettlementScreen//SettlementScreen.vue';
import HearingService from '@/services/API/hearings.service';
import EstimateParticularService from '@/services/API/estimate_particular.service';
import ScheduleService from '@/services/API/schedule.service';
import DecisionService from '@/services/API/decision.service';
import DeliveryService from '@/services/API/delivery.service.js';
import ProjectService from '@/services/API/project.service';
import UserService from '@/services/API/user.service';
import ClientService from '@/services/API/client.service';
import ManagementMasterService from '@/services/API/management_master.service';
import CreativeService from '@/services/API/creative.service';
import DecisionDetailService from '@/services/API/decision_detail.service';
import AddCostDetailService from '@/services/API/add_cost_details.service';

import ProposalGeneralService from '@/services/API/proposal_general.service';
import ReProposalGeneralService from '@/services/API/reproposal_general.service';
import TransferService from '@/services/API/transfer.service';

import SideBarRightConst from '@/constants/SideBarRight';
import CommonConst from '@/constants/CommonConst';
import LiveConst from '@/constants/LiveConst';
import creatorAppSocketListening from '@/mixin/creatorAppSocketListening';
import updateProjectStatusMixin from '@/mixin/updateProjectStatus';

import socketClient from '@/services/SOCKET';
import { mapState } from 'vuex';

const DUPLICATE_USER =
	'別ウインドウで既にCo-modeが起動している可能性があります\n 他のウインドウを閉じてください';
const SHARE_TIME_OUT = 'Co-mode Screenを終了します';

export default {
	name: 'CreatorApp',
	mixins: [creatorAppSocketListening, updateProjectStatusMixin],
	components: {
		SideBarLeft,
		SideBarRight,
		SideNavBar,
		ShareScreen,
		DrawerLeft,
		DrawerRight,
		LiveScreen,
		// CoCreateScreen,
		'add-on-folder-screen': AddOnFolder,
		'co-create-screen': CoCreateScreen,
		'live-design-screen': LiveDesign,
		'chat-screen': ChatScreen,
		'comode-ai-screen': ComodeAI,
		'memo-screen-full': MemoScreenFull,
		'settlement-screen': Settlement,
		MemoScreenMinimum,
		NotificationModal,
		CountDown,
	},
	data() {
		return {
			isSaveBtnShowed: true,
			userId: undefined,
			userImage: '',
			clientImage: '',
			defaultAvatar: ['ClientAvatar.png', 'CreatorAvatar.png'],
			liveScreenPlainModalId: 'live-screen-plain-modal-id',
			liveScreenPlainContents: 'ミーティングに参加しました',
			liveScreenSelectiveModalId: 'live-screen-selective-modal-id',
			liveScreenSelectiveContents: 'Co-modeを終了しますか？',

			shareScreenSelectiveModalId: 'share-screen-selective-modal-id',
			shareScreenSelectiveContents: 'Co-mode Screenを終了しますか？',
			shareScreenPlainModalId: 'share-screen-plain-modal-id',
			shareScreenPlainContents: '',

			comodeMode: '',
			comodeRequestModalId: 'comode-request-modal-id',
			comodeRequestContents: '',

			creatorAppLink: '',
			notiTitle: '',
			notiBody: '',
			notiType: '',
			projectInfo: {},
			path: '',

			rightMenuScreen: '',

			mobileView: false,
			isLogOutModalShowed: false,
			showQuestionNotification: false,
			showCreativeNotification: false,
			isShowClientModal: false,
			isShowClientModeAvatarLabel: false,

			//Show・Hide Drawer Left
			isShowDrawerLeft: false,

			//Show・Hide Drawer Right
			isShowDrawerRight: false,

			//Show・Hide Live Screen
			isLiveScreenMinimum: true,
			isShowLiveScreen: false,
			isLiveScreenSessionJoined: false,

			//Show・Hide Memo Screen
			isShowMemoScreen: false,
			isMemoScreenMinimum: false,

			isRightSideBarScreenShow: false,
			isAddOnScreenShow: false,

			// Show・Hide Share Screen
			isShareScreenMinimum: false,
			isShowShareScreen: false,
			isShareScreenExpandMode: false,
			isShareScreenHiding: false,

			onChat: false,
			messageEvent: 0,
			unSeenMessages: false,

			settlementItem: {
				route: `settlement`,
				isConfirm: false,
				disabled: false,
			},
			documentItem: {
				route: `download`,
				name: this.$t('general.sideBarLeftBtn.download'),
				icon: 'Download.svg',
				disabled: false,
			},
			screenFinishedItem: {
				route: `finish`,
				disabled: false,
			},
			sideBarLeftItems: [
				// add public route 27/7/2023 HoaDQ
				{
					route: `schedule`,
					name: this.$t('general.sideBarLeftBtn.schedule'),
					icon: 'Schedule.svg',
					isConfirm: false,
					disabled: true,
					inProcessing: false,
					isHidden: false,
				},
				{
					route: `hearing`,
					name: 'キックオフミーティング',
					icon: 'Estimate.svg',
					isConfirm: false,
					disabled: true,
					inProcessing: false,
					isHidden: false,
				},
				// {
				// 	route: `estimate`,
				// 	name: this.$t('general.sideBarLeftBtn.estimate'),
				// 	icon: 'Estimate.svg',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// },
				{
					route: `decision`,
					name: 'コンセプトデザイン',
					icon: 'Decision.svg',
					isConfirm: false,
					disabled: true,
					inProcessing: false,
				},
				// {
				// 	route: `creative`,
				// 	name: 'クリエイティブ',
				// 	icon: 'Creative.svg',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// },
				// {
				// 	route: `proposal`,
				// 	name: 'ご提案',
				// 	icon: 'Proposal.svg',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// },
				// {
				// 	route: `addcost`,
				// 	name: '再提案設定',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// 	isSubItem: true,
				// 	isHidden: true,
				// },
				// {
				// 	route: `reproposal`,
				// 	name: '再提案',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// 	isSubItem: true,
				// 	isHidden: true,
				// },
				{
					route: `delivery`,
					name: 'コンセプト仕上げ',
					icon: 'Creative.svg',
					isConfirm: false,
					disabled: true,
					inProcessing: false,
					isHidden: false,
				},
				{
					route: `nft`,
					name: 'デジタル認証',
					icon: 'Proposal.svg',
					isConfirm: false,
					disabled: true,
					inProcessing: false,
					isHidden: false,
				},
			],
			sideBarRightItems: [
				{
					name: SideBarRightConst.CHAT_SCREEN_NAME,
					tooltipText: 'チャット',
					iconName: 'Chat.svg',
					disabled: false,
				},
				{
					name: SideBarRightConst.LIVE_DESIGN_SCREEN_NAME,
					tooltipText: 'ホワイトボード',
					iconName: 'LiveDesign.svg',
					disabled: false,
				},
				{
					name: SideBarRightConst.COMODE_AI_SCREEN_NAME,
					tooltipText: 'AI',
					iconName: 'ComodeAi.svg',
					disabled: false,
				},
				{
					name: SideBarRightConst.CO_CREATE_SCREEN_NAME,
					tooltipText: 'Adobe',
					iconName: 'CoCreate.svg',
				},
				{
					name: SideBarRightConst.MEMO_SCREEN_NAME,
					tooltipText: '自分メモ',
					iconName: 'Memo.svg',
					disabled: false,
				},

				{
					name: SideBarRightConst.ADD_ON_FOLDER_SCREEN_NAME,
					tooltipText: 'ファイル保存',
					iconName: 'FolderAddOn.svg',
					disabled: false,
				},
				{
					name: SideBarRightConst.SETTLEMENT_SCREEN_NAME,
					tooltipText: '請求書作成',
					iconName: 'Download.svg',
					disabled: true,
				},
			],
			clientModalPath: undefined,

			creativeDetail: {},
			screenDatas: {
				hearing: {},
				schedule: {},
				estimateParticular: {},
				decision: {},
				creative: {},
				proposalGeneral: {},
				addCostDetails: [],
				reproposalGeneral: {},
				deliveries: [],
			},

			isSafari: !!window.safari,

			shareScreenMenuBarItems: [],

			counterDate: null,
		};
	},
	async created() {
		this.handleView();

		this.projectId = parseInt(this.$route.params.projectId);
		// Set projectId in store as current ProjectId
		this.$store.commit('setProjectId', this.projectId);

		this.token = this.$route.params.token;
		this.$store.commit('setToken', this.token);
		// Set userId in store as current creator_id

		let projectInfo = await this._getProject(this.projectId);
		this.projectInfo = projectInfo;
		this.userId = projectInfo['responsible_user'];
		this.$store.commit('setUserId', this.userId);
		this.$store.commit('setProjectInfo', projectInfo);
		document.title = this.projectInfo['project_name'] + ' ｜CoMoDe';

		let creatorInfo = await this._getUser(this.userId);
		this.$store.commit('setCreatorInfo', creatorInfo);
		this.$store.commit('setIsSoundEnabled', !!creatorInfo?.sound_enabled);

		this.userImage = await this._getPreviewImgUrl(
			creatorInfo['picture_key'],
			'31536000'
		);
		if (!this.userImage) {
			this.userImage = 'ClientAvatar.png';
		}

		this.$store.commit(
			'setCreatorAvatar',
			this.userImage == 'ClientAvatar.png'
				? CommonConst.CLIENT_AVATAR_URL
				: this.userImage
		);

		let clientInfo = await this._getClient(projectInfo['client_id']);
		this.clientImage = CommonConst.CLIENT_AVATAR_URL;
		let clientUserInfo = await this._getUser(clientInfo['id']);
		if (clientUserInfo) {
			this.clientImage = await this._getPreviewImgUrl(
				clientUserInfo['picture_key'],
				'31536000'
			);
			if (!this.clientImage) {
				this.clientImage = CommonConst.CLIENT_AVATAR_URL;
			}
		}
		this.$store.commit('setClientInfo', clientInfo);
		this.$store.commit('setClientAvatar', this.clientImage);

		let managementMasterInfo = await this._getManagementMaster(
			CommonConst.MANAGEMENT_MASTER_ID
		);
		this.$store.commit('setManagementMasterInfo', managementMasterInfo);

		let scheduleInfo = await this._getScheduleInfo(this.projectId);

		this.$store.commit('setSchedule', scheduleInfo);

		let appLogo = await this._getPreviewImgUrl('app-logo.png', '31536000');
		this.$store.commit('setAppLogo', appLogo);

		let appLogoPlain = await this._getPreviewImgUrl('app-logo-plain.png', '31536000');
		this.$store.commit('setAppLogoPlain', appLogoPlain);

		this.connectSocket();

		// speed up loading time
		await Promise.allSettled([
			await this._getHearingData(this.projectId),
			await this._getEstimateParticular(this.projectId),
			await this._getDecisionData(this.projectId),
			await this._getCreativeData(this.projectId),
			await this._getProposalGeneralData(this.projectId),
			await this._getReProposalGeneralData(this.projectId),
			await this._getDeliveryData(this.projectId),
		]);
		if (this.screenDatas.estimateParticular?.id)
			await this._getAddCostDetails(this.screenDatas.estimateParticular?.id);
	},

	computed: {
		...mapState([
			'creatorInfo',
			'schedule',
			'redDotIndexs',
			'isControlScrollDecision',
			'isNftUploading',
			'isShareCoCreate',
			'isCoCreateOpen',
		]),

		clientAvatar() {
			return require(`../../assets/RightSideBarIcon/ClientMode.svg`);
		},

		clientModeUserImage() {
			if (this.isShowClientModal) return 'CreatorAvatar.png';
			else return 'ClientAvatar.png';
		},

		clientModeAvatarLabelContent() {
			if (this.isShowClientModal) return 'クリエイター画面を表示';
			else return 'クライアント画面を表示';
		},

		isLeftSideBarScreenShow() {
			return this.isLiveScreenMinimum || !this.isShowLiveScreen;
		},
		mobileAndTabletCheck() {
			let check = false;
			(function (a) {
				if (
					//eslint-disable-next-line
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk|iPhone|iPad|iPod/i.test(
						a
					) ||
					//eslint-disable-next-line
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4)
					)
				)
					check = true;
			})(navigator.userAgent || navigator.vendor || window.opera);
			return check;
		},
	},

	watch: {
		$route() {
			this.updateClientRoute();
			// Remove Scroll Badge when change from Decision to another Screen
			this.$store.commit('setIsControlScrollDecision', false);
		},
		// isShowLiveScreen(newVal) {
		// 	this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName = newVal
		// 		? 'LiveActive.svg'
		// 		: 'Live.svg';
		// },
		// isShowShareScreen(newVal) {
		// 	this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].iconName = newVal
		// 		? 'ShareScreenActive.svg'
		// 		: 'ShareScreen.svg';
		// },

		isNftUploading(newIsNftUploading) {
			this.sideBarLeftItems.map((item, index) => {
				this.sideBarLeftItems[index].disabled = newIsNftUploading;
			});

			this.screenFinishedItem.disabled = newIsNftUploading;
			this.settlementItem.disabled = newIsNftUploading;
			this.documentItem.disabled = newIsNftUploading;
		},

		isShareCoCreate(newVal) {
			this.sideBarRightItems[CommonConst.CO_CREATE_INDEX].iconName = newVal
				? 'CoCreate.svg'
				: 'CoCreate.svg';
		},
		isShareScreenHiding(newVal) {
			this.shareScreenMenuBarItems = newVal ? this.$refs.shareScreen.menuBarItems : [];
		},
		'projectInfo.status': {
			handler(newStatus) {
				if (newStatus === 5) {
					this.sideBarRightItems[CommonConst.SETTLEMENT_INDEX].disabled = false;
					this.sideBarRightItems[CommonConst.SETTLEMENT_INDEX].iconName =
						'DownloadActive.svg';
				} else {
					this.sideBarRightItems[CommonConst.SETTLEMENT_INDEX].disabled = true;
				}
			},
		},
	},

	methods: {
		async updateClientRoute() {
			let isProposalCurrently = this.$route.fullPath.split('/').includes('proposal');

			if (!isProposalCurrently) this.clientModalPath = undefined;

			await this._getCreativeData(this.projectId);
			if (this.creativeDetail?.status !== 'onCountingDown')
				this.clientModalPath = undefined;
			else this.clientModalPath = 'creative';
		},

		forceLeaveLiveScreen() {
			this.isShowLiveScreen = false;
			this.isRightSideBarScreenShow = this.rightMenuScreen ? true : false;
			this.isLiveScreenSessionJoined = !this.isLiveScreenSessionJoined;
			this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = false;
			this.liveScreenPlainContents = LiveConst.TIME_OUT;
			this.$bvModal.show(`${this.liveScreenPlainModalId}`);
		},

		handleView() {
			this.mobileView = window.innerWidth <= CommonConst.MOBILE_SCREEN_MAX_WIDTH;
		},

		onOpenDrawerLeft() {
			this.isShowDrawerLeft = true;
		},

		onClickMyPage() {
			let url;
			if (this.creatorInfo.authority === CommonConst.AUTHORITY.CREATOR) {
				url = `/adminApp/creatormenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/mypage`;
			} else if (this.creatorInfo.authority === CommonConst.AUTHORITY.ADMIN) {
				url = `/adminApp/adminmenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/mypage`;
			}

			let routeData = this.$router.resolve({
				path: url,
			});

			window.open(routeData.href, '_blank');
		},

		onClickProjectMenu() {
			let url;
			if (this.creatorInfo.authority === CommonConst.AUTHORITY.CREATOR) {
				url = `/adminApp/creatormenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/projectmenu`;
			} else if (this.creatorInfo.authority === CommonConst.AUTHORITY.ADMIN) {
				url = `/adminApp/adminmenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/projectmenu`;
			}

			let routeData = this.$router.resolve({
				path: url,
			});

			window.open(routeData.href, '_blank');
		},

		onClickEditProject() {
			let url;
			if (this.creatorInfo.authority === CommonConst.AUTHORITY.CREATOR) {
				url = `/adminApp/creatormenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/editproject`;
			} else if (this.creatorInfo.authority === CommonConst.AUTHORITY.ADMIN) {
				url = `/adminApp/adminmenu/${this.creatorInfo.id}/${this.creatorInfo.authority}/editproject`;
			}

			let routeData = this.$router.resolve({
				path: url,
				query: {
					projectid: this.projectId,
				},
			});

			window.open(routeData.href, '_blank');
		},

		onUpdateAvatar(newImg) {
			this.userImage = newImg;
			this.$store.commit('setCreatorAvatar', newImg);
		},
		onOpenDrawerRight() {
			this.isShowDrawerRight = true;
		},

		closeDrawer() {
			this.isShowDrawerLeft = false;
			this.isShowDrawerRight = false;
		},

		showClientModal() {
			window.open(
				this.projectInfo.process_client_mode_url +
					window.location.pathname.split('/').at(-1),
				'_blank'
			);
		},

		onCloseLiveScreen({ plainModal, selectiveModal }) {
			if (selectiveModal) {
				this.$bvModal.show(`${this.liveScreenSelectiveModalId}`);
				return;
			}
			this.liveScreenPlainContents = 'ミーティングに参加しました';
			if (plainModal) {
				this.$bvModal.show(`${this.liveScreenPlainModalId}`);
			} else {
				this.isRightSideBarScreenShow = false;
			}
			this.isShowLiveScreen = false;
			this.isLiveScreenSessionJoined = !this.isLiveScreenSessionJoined;
			this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = false;
		},

		onDuplicateUser() {
			this.liveScreenPlainContents = DUPLICATE_USER;
			this.$bvModal.show(`${this.liveScreenPlainModalId}`);

			this.isShowLiveScreen = false;

			this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = false;
		},
		onDuplicateUserShare() {
			this.liveScreenPlainContents = DUPLICATE_USER;
			this.$bvModal.show(`${this.liveScreenPlainModalId}`);
			this.isShowShareScreen = false;
			this.isRightSideBarScreenShow = false;
			this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = false;
			this.$store.commit('setIsAppProcessing', false);
		},
		onSeenMessage: async function () {
			this.messageEvent += 1;
		},

		onOpenChatRoom() {
			this.$refs.sideBarRight.toggle(SideBarRightConst.CHAT_SCREEN_NAME);
		},
		onOpenSettlement() {
			this.$refs.sideBarRight.toggle(SideBarRightConst.SETTLEMENT_SCREEN_NAME);
		},
		onUpdateProjectStatus(status) {
			console.log('update Project status', status);
			this.projectInfo = { ...this.projectInfo, status: status };
			this.$store.commit('setProjectInfo', this.projectInfo);
		},

		onLeftMenuItemClick() {
			this.$refs.sideBarRight.activeItem = '';
			this.revertSideBarRightState();
		},

		revertSideBarRightState() {
			if (this.isShowLiveScreen === true) {
				this.isLiveScreenMinimum = true;
			}

			if (this.isShowShareScreen === true) {
				this.isShareScreenHiding = true;
			}

			if (this.rightMenuScreen === 'memo-screen-full') {
				this.isMemoScreenMinimum = true;
				this.isAddOnScreenShow = false;
				this.rightMenuScreen = '';
			}

			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow = false;
			this.$refs.sideBarRight.toggle(undefined);
		},

		/**s
		 * Show Sub-proposal item in sideBarLeft
		 */
		onSideBarLeftSubProposalShow() {
			this.sideBarLeftItems[CommonConst.SCREEN_ID.ADDCOST]['isHidden'] = false;
			this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['isHidden'] = false;

			// Revert Reproposal Name
			this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['name'] =
				CommonConst.RE_PROPOSAL_SCREEN_NAME;
		},

		onShowDownloadScreenButton() {
			this.sideBarLeftItems[CommonConst.SCREEN_ID.ESTIMATE]['isConfirm'] = true;
		},

		/**
		 * Switch Live Screen Mode
		 * @param {Boolean} screenMode
		 */
		onSwitchLiveScreenMode(screenMode) {
			this.isLiveScreenMinimum = screenMode;
			this.isAddOnScreenShow = false;
			this.rightMenuScreen = '';
			if (screenMode) {
				this.isRightSideBarScreenShow = false;
				return;
			} else {
				this.isRightSideBarScreenShow = true;
			}
		},
		onChangeProposalName(newName, screenId) {
			if (screenId === CommonConst.SCREEN_ID.PROPOSAL)
				this.sideBarLeftItems[CommonConst.SCREEN_ID.PROPOSAL]['name'] = newName;
			if (screenId === CommonConst.SCREEN_ID.REPROPOSAL)
				this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['name'] = newName;
		},

		onCloseMemoScreenFull() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
			this.onChat = false;
		},

		onCloseMemoScreenMinimum() {
			this.isMemoScreenMinimum = false;
		},

		onCloseCoCreateScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.isSaveBtnShowed = true;
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
			this.$store.commit('setIsCoCreateOpen', false);
			if (this.isShareCoCreate) {
				socketClient.send('close_co_create_screen', {
					room: this.projectInfo.id,
				});
			}
		},

		onCloseAddOnFolder() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
		},

		onCloseChatScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
			this.onChat = false;
		},

		onCloseComodeAiScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
		},
		onCloseSettlementScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
		},
		onCloseLiveDesignScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
		},

		onCloseShareScreen() {
			this.$bvModal.show(this.shareScreenSelectiveModalId);
		},

		onLeaveShareScreenSeesion() {
			this.isShareScreenHiding = false;
			this.isShareScreenExpandMode = false;
			this.$refs.sideBarRight.activeItem = '';
			this.isShowShareScreen = false;
			this.isRightSideBarScreenShow =
				this.rightMenuScreen || (this.isShowMemoScreen && !this.isMemoScreenMinimum)
					? true
					: false;
			this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = false;
		},

		onShareScreenSeesionTimeOut() {
			this.isShareScreenExpandMode = false;
			this.$refs.sideBarRight.activeItem = '';
			this.isShowShareScreen = false;
			this.isRightSideBarScreenShow =
				this.rightMenuScreen || this.isShowMemoScreen ? true : false;
			this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = false;
			this.liveScreenPlainContents = SHARE_TIME_OUT;
			this.$bvModal.show(`${this.liveScreenPlainModalId}`);
		},

		onOpenCoCreation() {
			this.forceLiveScreenToMimimun();

			if (this.projectInfo.status === CommonConst.SCREEN_ID.ADDCOST) {
				this.isSaveBtnShowed = false;
			}
			this.rightMenuScreen = 'co-create-screen';
			this.isAddOnScreenShow = true;
			this.$store.commit('setIsCoCreateOpen', true);
			socketClient.send('open_co_create_screen', {
				room: this.projectInfo.id,
			});
		},

		onToggleRightMenuScreen(screenName) {
			switch (screenName) {
				case SideBarRightConst.LIVE_SCREEN_NAME:
					if (!this.isShowLiveScreen) {
						this.isShowLiveScreen = true;
						this.isLiveScreenMinimum = true;
						this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = true;
						this.$store.commit('setIsAppProcessing', true);
					}
					return;

				case SideBarRightConst.MEMO_SCREEN_NAME:
					if (this.rightMenuScreen === 'memo-screen-full') {
						this.onCloseMemoScreenFull();
						return;
					}

					if (this.isMemoScreenMinimum) {
						this.isMemoScreenMinimum = false;
						return;
					}

					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'memo-screen-full';
					this.isAddOnScreenShow = true;
					this.onChat = true;
					return;

				case SideBarRightConst.CHAT_SCREEN_NAME:
					if (this.rightMenuScreen === 'chat-screen') {
						this.onCloseChatScreen();
						return;
					}
					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'chat-screen';
					this.isAddOnScreenShow = true;
					this.onChat = true;
					return;

				case SideBarRightConst.CO_CREATE_SCREEN_NAME:
					if (this.rightMenuScreen === 'co-create-screen') {
						this.onCloseCoCreateScreen();
						return;
					}

					this.$store.commit('setIsCoCreateOpen', true);

					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'co-create-screen';
					this.isAddOnScreenShow = true;

					if (this.isShareCoCreate) {
						socketClient.send('open_co_create_screen', {
							room: this.projectInfo.id,
						});
					}
					return;

				case SideBarRightConst.LIVE_DESIGN_SCREEN_NAME:
					if (this.rightMenuScreen === 'live-design-screen') {
						this.onCloseLiveDesignScreen();
						return;
					}

					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'live-design-screen';
					this.isAddOnScreenShow = true;
					return;

				case SideBarRightConst.ADD_ON_FOLDER_SCREEN_NAME:
					if (this.rightMenuScreen === 'add-on-folder-screen') {
						this.onCloseAddOnFolder();
						return;
					}

					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'add-on-folder-screen';
					this.isAddOnScreenShow = true;
					return;

				case SideBarRightConst.SHARE_SCREEN_NAME:
					if (this.mobileAndTabletCheck) {
						this.liveScreenPlainContents = LiveConst.NOT_SUPPORT_SHARE_MOBILE;
						this.$bvModal.show(`${this.liveScreenPlainModalId}`);
						return;
					}
					// if (this.isSafari) {
					// 	this.liveScreenPlainContents = LiveConst.NOT_SUPPORT_SHARE_SAFARI;
					// 	this.$bvModal.show(`${this.liveScreenPlainModalId}`);
					// 	return;
					// }
					if (!this.isShowShareScreen) {
						this.rightMenuScreen = '';
						this.isAddOnScreenShow = false;
						// this.isShowMemoScreen = false;
						this.isRightSideBarScreenShow = true;
						this.isShowShareScreen = true;
						this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = true;
						this.$store.commit('setIsAppProcessing', true);
					}
					return;

				case SideBarRightConst.COMODE_AI_SCREEN_NAME:
					if (this.rightMenuScreen === 'comode-ai-screen') {
						this.onCloseComodeAiScreen();
						return;
					}

					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'comode-ai-screen';
					this.isAddOnScreenShow = true;
					this.onChat = true;
					return;
				case SideBarRightConst.SETTLEMENT_SCREEN_NAME:
					if (this.rightMenuScreen === 'settlement-screen') {
						this.onCloseSettlementScreen();
						return;
					}
					this.forceLiveScreenToMimimun();
					this.rightMenuScreen = 'settlement-screen';
					this.isAddOnScreenShow = true;
					this.onChat = true;
					return;
			}
		},

		onChangeSessionJoined(newSessionJoined) {
			this.isLiveScreenSessionJoined = newSessionJoined;
		},

		onLeaveLiveScreenSeesion() {
			if (this.isShowLiveScreen && !this.isLiveScreenMinimum) {
				this.isRightSideBarScreenShow = false;
			}
			this.isShowLiveScreen = false;
			this.isLiveScreenSessionJoined = !this.isLiveScreenSessionJoined;
			this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = false;
		},

		/**
		 * Handle resize MemoScreen
		 * @param {Object} {isMemoScreenMinimum} : true at minisize, false at full size
		 */
		onSwitchMemoScreenMode({ isMemoScreenMinimum }) {
			this.isMemoScreenMinimum = isMemoScreenMinimum;
			if (isMemoScreenMinimum) {
				this.forceLiveScreenToMimimun();
				this.$refs.sideBarRight.activeItem = '';
				this.rightMenuScreen = '';
				this.isAddOnScreenShow = false;
				this.isRightSideBarScreenShow =
					this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
			} else {
				this.forceLiveScreenToMimimun();
				this.rightMenuScreen = 'memo-screen-full';
				this.isAddOnScreenShow = true;
			}
		},

		onSwitchShareScreenMode() {
			this.isShareScreenExpandMode = !this.isShareScreenExpandMode;
		},
		// Force LiveScreen to minimum whenever open other screens
		forceLiveScreenToMimimun() {
			if (this.isShowLiveScreen && !this.isLiveScreenMinimum) {
				this.isLiveScreenMinimum = true;
				this.isRightSideBarScreenShow = false;
			}
		},

		async onCheckStartInfo(data) {
			if (data) {
				await this._getProject(this.projectId);
			}
		},

		onUnseenMessages(unSeenMessages) {
			this.unSeenMessages = unSeenMessages;
		},

		/**
		 * Get Confirm Data from child Screen
		 * @param {Object} data
		 * data["screenId"]: child Screen 's Id
		 * data["confirmInfo"]: {booleen}
		 */
		onGetConfirmInfo(data) {
			// Finish when delivery confirm
			if (data.screenId === this.sideBarLeftItems.length - 1) {
				this.sideBarLeftItems[data.screenId]['isConfirm'] = data.confirmInfo;
				this.sideBarLeftItems[data.screenId]['inProcessing'] = false;
				return;
			}

			// Add Cocreate button in sideBarRight when Estimate confimed
			if (data.screenId === CommonConst.SCREEN_ID.HEARING) {
				this.$store.commit('setIsShareCoCreate', true);
			} else {
				this.$store.commit('setIsShareCoCreate', false);
			}

			if (data.isReproposal) {
				if (data.screenId === CommonConst.SCREEN_ID.ADDCOST) {
					this.sideBarLeftItems[data.screenId + 1]['inProcessing'] = false;
					this.sideBarLeftItems[data.screenId]['inProcessing'] = true;
				} else {
					this.sideBarLeftItems[data.screenId]['isConfirm'] = data.confirmInfo;
					this.sideBarLeftItems[data.screenId]['inProcessing'] = false;
					this.sideBarLeftItems[data.screenId + 1]['inProcessing'] = true;
					this.sideBarLeftItems[data.screenId + 1]['disabled'] = false;
				}
			}

			// Force direct to delivery screen
			if (data.screenId === CommonConst.SCREEN_ID.PROPOSAL && !data.isReproposal) {
				this.sideBarLeftItems[data.screenId + 3]['inProcessing'] = true;
				this.sideBarLeftItems[data.screenId + 3]['disabled'] = false;
				this.sideBarLeftItems[data.screenId]['inProcessing'] = false;
				this.sideBarLeftItems[data.screenId]['isConfirm'] = true;
			}
			// Direct to Reproposal Screen
			else if (data.screenId === CommonConst.SCREEN_ID.ADDCOST && data.isReproposal) {
				this.sideBarLeftItems[data.screenId + 1]['inProcessing'] = false;
				this.sideBarLeftItems[data.screenId]['inProcessing'] = true;
			} else {
				this.sideBarLeftItems[data.screenId]['isConfirm'] = data.confirmInfo;
				this.sideBarLeftItems[data.screenId]['inProcessing'] = false;
				this.sideBarLeftItems[data.screenId + 1]['inProcessing'] = true;
				this.sideBarLeftItems[data.screenId + 1]['disabled'] = false;
			}
		},

		/**
		 * Get requirementScreen's cofirm_flag info
		 * @param {String} projectId
		 */
		async _getHearingData(projectId) {
			let response = await HearingService.get(projectId);
			if (response && response.status === 200) {
				if (response.data['confirm_flag'] === CommonConst.CONFIRMED) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.HEARING]['isConfirm'] = true;
				}

				this.screenDatas.hearing = response.data;
			} else {
				// TODO: Error notification
				console.log('Get requirement details failed');
			}
			return;
		},

		/**
		 * Get estimateScreen's cofirm_flag info
		 * @param {String} projectId
		 */
		async _getEstimateParticular(projectId) {
			let response = await EstimateParticularService.get(projectId);
			if (response && response.status === 200) {
				let estimateParticular = response.data['estimate_particular'];
				let confirmFlag = estimateParticular['confirm_flag'];
				if (confirmFlag === CommonConst.CONFIRMED) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.ESTIMATE]['isConfirm'] = true;
				}

				this.screenDatas.estimateParticular = estimateParticular;
			} else {
				// TODO: Error notification
				console.log('Get estimate particular failed');
			}
		},

		/**
		 * Get Schedule Info from DB
		 * @param {Number} projectId
		 */
		async _getScheduleInfo(projectId) {
			let response = await ScheduleService.get(projectId);
			if (!response || response.status !== 200) {
				throw 'Get Schedule Info failed';
			}

			if (response.data['confirm_flag'] === CommonConst.CONFIRMED) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.SCHEDULE]['isConfirm'] = true;
			}

			this.screenDatas.schedule = response.data;
			return response.data;
		},

		/**
		 * Get Hearing data
		 * @param {Integer} projectId project ID
		 * @returns
		 */
		async _getDecisionData(projectId) {
			let response = await DecisionService.get(projectId);

			if (!response || response.status !== 200) {
				throw 'Get Hearing failed';
			}

			if (response.data['confirm_flag'] === CommonConst.CONFIRMED) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DECISION]['isConfirm'] = true;
			}

			this.screenDatas.decision = response.data;

			return response.data;
		},

		/**
		 * Get Proposal General data
		 * @param {Integer} projectId project ID
		 * @returns
		 */
		async _getProposalGeneralData(projectId) {
			let response = await ProposalGeneralService.get(projectId);

			if (!response || response.status !== 200) {
				throw 'Get Hearing failed';
			}

			if (response.data['screen_mode'] === CommonConst.REVISION_MODE_NUMB) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.PROPOSAL]['name'] =
					CommonConst.REVISION_SCREEN_NAME;
			} else if (response.data['screen_mode'] === CommonConst.PROPOSAL_MODE_NUMB) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.PROPOSAL]['name'] =
					CommonConst.PROPOSAL_SCREEN_NAME;
			}
		},

		/**
		 * Get data from Add Cost Details Table
		 * @param {String} quoteId
		 * @returns Add Cost Details 's Data
		 */
		async _getAddCostDetails(quoteId) {
			try {
				let response = await AddCostDetailService.get(quoteId);
				if (response && response.status !== 200) {
					throw 'Get add cost detail failed';
				}
				let addCostDetails = response.data;
				this.screenDatas.addCostDetails = addCostDetails;
			} catch (error) {
				console.log(`Get add_cost detail failed: ${error}`);
			}
		},

		/**
		 * Get ReProposal General data
		 * @param {Integer} projectId project ID
		 * @returns
		 */
		async _getReProposalGeneralData(projectId) {
			try {
				let response = await ReProposalGeneralService.get(projectId);
				if (response.status !== 200) {
					throw 'Get proposal general failed';
				}

				if (response.data['screen_mode'] === CommonConst.REVISION_MODE_NUMB) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['name'] =
						CommonConst.RE_REVISION_SCREEN_NAME;
				} else if (response.data['screen_mode'] === CommonConst.PROPOSAL_MODE_NUMB) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['name'] =
						CommonConst.RE_PROPOSAL_SCREEN_NAME;
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 * Get DeliveryData
		 * @param {Integer} projectId Project ID
		 * @returns
		 */
		async _getDeliveryData(projectId) {
			try {
				let response = await DeliveryService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Delivery failed!';
				}

				let uploadedContents = [...response.data].reverse();

				if (
					uploadedContents &&
					uploadedContents.length > 0 &&
					uploadedContents[0]['is_save'] === 3
				) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.DELIVERY]['isConfirm'] = true;
				}

				this.screenDatas.deliveries = uploadedContents;

				console.log('%c Get Delivery successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},

		async _getProject(projectId) {
			try {
				let response = await ProjectService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Project failed!';
				}

				console.log('%c Get Project successfully!', 'color:green');
				let projectInfo = response.data;
				this._getInProcessingScreenInfo(projectInfo);
				return projectInfo;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get image preview url
		 * @param {String} imgKey Image key to get image url
		 * @param {String} expiration The lifetime of the url in seconds
		 * @returns image url
		 */
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: green');
				return response.data.link;
			} catch (error) {
				console.log(error);
			}
		},

		_getInProcessingScreenInfo(projectInfo) {
			let inProcessScreenIdx = projectInfo.status;
			let showSubMenu = Boolean(projectInfo['sub_menu_flag']);
			if (projectInfo['only_decision'] === 1) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.SCHEDULE]['isHidden'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.HEARING]['isHidden'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DELIVERY]['isHidden'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.NFT]['isHidden'] = true;
			} else {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.SCHEDULE]['isHidden'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.HEARING]['disabled'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DECISION]['disabled'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DECISION]['inProcessing'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.HEARING]['isHidden'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DELIVERY]['isHidden'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.NFT]['isHidden'] = false;
			}
			if (
				inProcessScreenIdx === CommonConst.SCREEN_ID.DECISION ||
				inProcessScreenIdx === CommonConst.SCREEN_ID.ADDCOST
			) {
				this.$store.commit('setIsShareCoCreate', true);
			} else {
				this.$store.commit('setIsShareCoCreate', false);
			}

			// Show Proposal sub-item
			if (
				inProcessScreenIdx === CommonConst.SCREEN_ID.ADDCOST ||
				inProcessScreenIdx === CommonConst.SCREEN_ID.REPROPOSAL ||
				showSubMenu
			) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.ADDCOST]['isHidden'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['isHidden'] = false;
				if (inProcessScreenIdx === CommonConst.SCREEN_ID.ADDCOST) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['disabled'] = false;
					this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['isConfirm'] = true;
				}
			}

			// Check processing status
			let sideBarLeftItems = [...this.sideBarLeftItems];
			for (let i = 0; i < sideBarLeftItems.length; i++) {
				if (i < inProcessScreenIdx) {
					this.sideBarLeftItems[i]['disabled'] = false;
					this.sideBarLeftItems[i]['isConfirm'] = true;
				}

				if (i === inProcessScreenIdx && projectInfo.creator_start === 1) {
					this.sideBarLeftItems[i]['disabled'] = false;
					this.sideBarLeftItems[i]['inProcessing'] = true;
				}
			}

			// Finish behavior
			if (inProcessScreenIdx === this.sideBarLeftItems.length) {
				this.sideBarLeftItems[inProcessScreenIdx - 1]['disabled'] = false;
				this.sideBarLeftItems[inProcessScreenIdx - 1]['inProcessing'] = false;
				this.sideBarLeftItems[inProcessScreenIdx - 1]['isConfirm'] = true;
			}
		},

		async _getClient(clientId) {
			try {
				let response = await ClientService.get(clientId);
				if (!response || response.status !== 200) {
					throw 'Get Client failed!';
				}

				console.log('%c Get Client successfully!', 'color: green');
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},

		async _getUser(usertId) {
			try {
				let response = await UserService.get(usertId);
				if (!response || response.status !== 200) {
					throw 'Get User failed!';
				}

				console.log('%c Get User successfully!', 'color:green');
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},

		async _getManagementMaster(id) {
			try {
				let response = await ManagementMasterService.get(id);
				if (!response || response.status !== 200) {
					throw 'Get User failed!';
				}

				console.log('%c Get Management Master successfully!', 'color:green');
				let managementMasterInfo = response.data;
				return managementMasterInfo;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get getUnReadAnswers
		 * */
		async getUnReadAnswers() {
			let decisionData = await this._getDecisionData(this.projectId);
			if (!decisionData.id) {
				return [];
			}
			let query = {
				decision_id: decisionData.id,
				answered_flag: 1,
				readed_flag: 0,
			};
			return await DecisionDetailService.search(query)
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					console.log(error);
					return [];
				});
		},

		async onReadDecisionQuestion() {
			let getUnReadAnswers = await this.getUnReadAnswers();
			this.showQuestionNotification =
				getUnReadAnswers.length > 0 ? true : false ?? false;
		},

		async _getCreativeData(projectId) {
			try {
				let response = await CreativeService.get(projectId);
				if (response.status !== 200) {
					throw 'Get Creative Data failed!';
				}
				console.log('%c Get Creative Data success!', 'color: green');
				this.creativeDetail = response.data;
				let creativeDetails = response.data.creative_details
					? response.data.creative_details
					: [];

				if (creativeDetails.length > 0) {
					this.showCreativeNotification = creativeDetails.some(
						(item) => item.readed_flag === 0 && item.answered_flag === 1
					);
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},
		async _getCreativeCounterDate(projectId) {
			let response = await CreativeService.get(projectId);
			if (response.status !== 200) {
				throw 'Get Creative Counter Date failed!';
			}
			if (response.data['scheduled_date']) {
				return response.data['scheduled_date'];
			}
			return null;
		},
		async onUpdateCreativeQuestionList() {
			await this._getCreativeData(this.projectId);
		},

		async updateProject() {
			let projectInfo = await this._getProject(this.projectId);
			this.projectInfo = projectInfo;
			this.$store.commit('setProjectInfo', projectInfo);
		},
		notifyZoom(comodeMode) {
			this.comodeMode = comodeMode;
			this.comodeRequestContents = LiveConst.REQUEST_TALKING;
			if (comodeMode == 'talk') {
				this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName =
					'LiveActive.svg';
				this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = true;
			}
			this.$bvModal.show(this.comodeRequestModalId);
		},
		openComode() {
			this.onToggleRightMenuScreen(SideBarRightConst.LIVE_SCREEN_NAME);
		},
		onShowShareScreen() {
			this.isAddOnScreenShow = false;
			if (this.rightMenuScreen === 'memo-screen-full') {
				this.isMemoScreenMinimum = true;
			}
			this.rightMenuScreen = '';
			this.isRightSideBarScreenShow = true;
			this.isShareScreenHiding = false;
		},
		onShowShareScreenController() {
			this.$refs.shareScreen.onShowShareScreen();
		},
		onMinimumBarItemClickShareScreenController(id) {
			this.$refs.shareScreen.onMinimumMenuBarItemClick(id);
		},
		async startCreativeCountDown() {
			if (
				this.projectInfo.status == CommonConst.SCREEN_ID.CREATIVE ||
				this.projectInfo.status == CommonConst.SCREEN_ID.PROPOSAL
			) {
				this.counterDate = await this._getCreativeCounterDate(this.projectInfo.id);
			}
		},
		async onHandleTimeOutCreativeCountDown() {
			if (
				this.projectInfo.status === CommonConst.SCREEN_ID.CREATIVE ||
				this.projectInfo.status === CommonConst.SCREEN_ID.PROPOSAL
			) {
				let routeName = this.$router.currentRoute.path.split('/').at(-1);
				if (routeName == 'creative') {
					return;
				}
				await this.updateProjectStatus(
					this.projectId,
					CommonConst.SCREEN_ID.CREATIVE + 1
				);
				let confirmInfo = {
					screenId: CommonConst.SCREEN_ID.CREATIVE,
					confirmInfo: true,
				};
				this.onGetConfirmInfo(confirmInfo);
				this.$router.push('proposal');
			}
		},
	},

	async mounted() {
		window.addEventListener('resize', this.handleView);
		let getUnReadAnswers = await this.getUnReadAnswers();
		console.log('getUnReadAnswers', getUnReadAnswers);
		if (getUnReadAnswers.length > 0) {
			this.showQuestionNotification = true;
		}
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'zoom_meeting_end':
					this.updateProject();
					break;
				case 'admin_update_project':
					this.updateProject();
					break;
				// case 'is_cocreating':
				// 	this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName =
				// 		data.isShowLiveScreen ? 'LiveActive.svg' : 'Live.svg';
				// 	this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled =
				// 		data.isShowLiveScreen;
				// 	break;
				default:
					break;
			}
		});
		socketClient.listen('request_comode', async (data) => {
			if (data.mode == 'check') {
				socketClient.send('data_transfer', {
					room: this.projectId,
					data: {
						event_name: 'is_cocreating',
						isShowLiveScreen: this.isShowLiveScreen,
						isShowShareScreen: this.isShowShareScreen,
					},
				});
			} else if (data.mode != 'close') {
				this.notifyZoom(data.mode);
			} else {
				// this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName = this
				// 	.isShowLiveScreen
				// 	? 'LiveActive.svg'
				// 	: 'Live.svg';
				// this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].iconName = this
				// 	.isShowShareScreen
				// 	? 'ShareScreenActive.svg'
				// 	: 'ShareScreen.svg';
				// this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = this
				// 	.isShowShareScreen
				// 	? true
				// 	: false;
				// this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = this
				// 	.isShowLiveScreen
				// 	? true
				// 	: false;
				this.$bvModal.hide(this.comodeRequestModalId);
			}
		});

		let interval = setInterval(async () => {
			if (this.projectInfo) {
				socketClient.send('request_comode', {
					room: this.projectInfo.id,
					data: {
						mode: 'check',
					},
				});
				this.startCreativeCountDown();
				clearInterval(interval);
			}
		}, 1000);
	},

	beforeDestroy: function () {
		window.removeEventListener('resize', this.handleView());
	},
};
