import socketClient from '@/services/SOCKET';
import CommonConst from '@/constants/CommonConst';
import emitSoundMixin from '@/mixin/emitSound';

const SAVED_NUMBER = 1;
const CONFIRMED_NUMBER = 2;
// const FINISH_NUMBER = 3;

export default {
	mixins: [emitSoundMixin],
	watch: {
		'screenDatas.hearing.status'(hearingStatus) {
			this.setHearingRedDotIndex(hearingStatus);
		},
		'screenDatas.schedule.status'(scheduleStatus) {
			this.setScheduleRedDotIndex(scheduleStatus);
		},
		'screenDatas.estimateParticular.status'(estimateStatus) {
			this.setEstimateRedDotIndex(estimateStatus);
		},
		'screenDatas.decision.status'(decisionStatus) {
			this.setDecisionRedDotIndex(decisionStatus);
		},
		'screenDatas.addCostDetails': {
			handler(addCostDetails) {
				const addCostStatus = addCostDetails[addCostDetails.length - 1]?.status;
				this.setAddCostRedDotIndex(addCostStatus);
			},
			deep: true,
		},
		'screenDatas.deliveries': {
			handler(deliveries) {
				const deliveryStatus = deliveries[deliveries.length - 1]?.is_save;
				this.setDeliveryRedDotIndex(deliveryStatus);
			},
			deep: true,
		},
	},
	methods: {
		/**
		 * Get Listen socket event
		 * */
		connectSocket() {
			socketClient.send('join', {
				userId: this.userId,
				projectId: this.projectId,
			});

			socketClient.listen('new_join', (data) => {
				console.log(data);
			});

			// Join room with AdminApp
			socketClient.send('join', {
				userId: this.projectInfo['responsible_user'],
				room: 'user' + this.projectInfo['responsible_user'],
			});

			socketClient.listen('new_question_list_updated', async (data) => {
				console.log('new_question_list_updated', data);
				let getUnReadAnswers = await this.getUnReadAnswers();
				console.log('getUnReadAnswers', getUnReadAnswers);
				if (getUnReadAnswers.length > 0) {
					this.showQuestionNotification = true;
				} else {
					this.showQuestionNotification = false;
				}
			});

			socketClient.listen('new_creative_question_list_updated', async (data) => {
				console.log('new_creative_question_list_updated', data);
				await this._getCreativeData(this.projectId);
			});

			this.listenUpdatingScreenStatus();
		},

		listenUpdatingScreenStatus() {
			socketClient.listen('new_data_transfer', async (data) => {
				switch (data?.event_name) {
					case 'update_creator_info':
						this.$store.commit('setCreatorInfo', await this._getUser(this.userId));
						break;
					case 'toggle_creator_sound_enabled':
						console.log('toggle_creator_sound_enabled');
						this.$store.commit('setIsSoundEnabled', !!data?.content);
						break;
					case 'hearing_accept_confirm':
						this.setHearingRedDotIndex(CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM);
						break;
					case 'hearing_deny_confirm':
						this.setHearingRedDotIndex(CommonConst.SCREEN_STATUS.DENY_CONFIRM);
						break;
					case 'schedule_retry':
						this.setScheduleRedDotIndex(CommonConst.SCREEN_STATUS.DENY_CONFIRM);
						break;
					case 'estimate_retrying_reason':
						this.setEstimateRedDotIndex(CommonConst.SCREEN_STATUS.DECLINE);
						break;
					case 'decision_answer_confirm':
						this.setDecisionRedDotIndex(data.content?.status);
						break;
					case 'delivery_confirm':
						this.setDeliveryRedDotIndex(SAVED_NUMBER);
						break;
					case 'emit_finishing_sound':
						this.emitMixinFinishingSound(false);
						break;

					default:
						break;
				}
			});

			socketClient.listen('new_schedule_confirm', () => {
				this.setScheduleRedDotIndex(CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM);
			});

			socketClient.listen('creator_received_addcost_confirm', async () => {
				this.setAddCostRedDotIndex(CommonConst.SCREEN_STATUS.ACCEPT);
			});

			socketClient.listen('addcost_received_required', () => {
				this.setAddCostRedDotIndex(CommonConst.SCREEN_STATUS.DECLINE);
			});
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} hearingStatus  Screen Status
		 */
		setHearingRedDotIndex(hearingStatus) {
			if (
				hearingStatus === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM ||
				hearingStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM
			) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.HEARING]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.HEARING
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} scheduleStatus  Screen Status
		 */
		setScheduleRedDotIndex(scheduleStatus) {
			if (
				scheduleStatus === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM ||
				scheduleStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM
			) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.SCHEDULE]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.SCHEDULE
				);

				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} estimateStatus  Screen Status
		 */
		setEstimateRedDotIndex(estimateStatus) {
			if (
				estimateStatus === CommonConst.SCREEN_STATUS.ACCEPT ||
				estimateStatus === CommonConst.SCREEN_STATUS.DECLINE
			) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.ESTIMATE]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.ESTIMATE
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} decisionStatus  Screen Status
		 */
		setDecisionRedDotIndex(decisionStatus) {
			if (
				decisionStatus === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM ||
				decisionStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM
			) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.DECISION]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.DECISION
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} addCostStatus  Screen Status
		 */
		setAddCostRedDotIndex(addCostStatus) {
			if (
				addCostStatus === CommonConst.SCREEN_STATUS.ACCEPT ||
				addCostStatus === CommonConst.SCREEN_STATUS.DECLINE
			) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.ADDCOST]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.ADDCOST
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		setDeliveryRedDotIndex(deliveryStatus) {
			if (deliveryStatus === CONFIRMED_NUMBER) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.DELIVERY]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.DELIVERY
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		/**
		 *
		 * @param {Array} redDotIndexs
		 * @param {Integer} removingScreenId
		 * @return {Array} Array after remove screenId
		 */
		removeScreenId(redDotIndexs, removingScreenId) {
			return redDotIndexs.filter((screenId) => screenId !== removingScreenId);
		},
	},
};
